<template>
  <div class="printView">
    <div class="printView_top">
      <el-radio-group v-model="activePrintType">
        <el-radio label="1"
                  border>面单浏览</el-radio>
        <el-radio label="2"
                  border>发票浏览</el-radio>
      </el-radio-group>
    </div>
    <div class="printView_main">
      <div class="main_left">
        <div class="left_title">
          <span>{{activeTypeName}}控制中心</span>
        </div>
        <div class="left_view">
          <el-form :model="orderData"
                   ref="orderData"
                   label-position="top">
            <el-form-item label="主单号"
                          style="width:100%;"
                          prop="wlnum"
                          :rules="[
      { required: true, message: '物流单号不能为空'},
    ]">
              <el-input v-model="orderData.wlnum"
                        placeholder="请输入物流单号"></el-input>
            </el-form-item>

            <el-form-item label="收件人"
                          v-if="activePrintType==1"
                          prop='sjname'
                          :rules="[
      { required: true, message: '收件人姓名不能为空'}
    ]">
              <el-input v-model="orderData.sjname"
                        placeholder="请输入收件人姓名"></el-input>
            </el-form-item>

            <el-form-item label="联系电话"
                          v-if="activePrintType==1"
                          style="width:65%; "
                          prop='sjmobile'
                          :rules="[
      { required: true, message: '联系电话不能为空'}
    ]">
              <el-input v-model="orderData.sjmobile"
                        placeholder="请输入收件人电话号码"></el-input>
            </el-form-item>

            <el-form-item label="寄往国家"
                          prop='gjname'
                          :rules="[
      { required: true, message: '联系电话不能为空'}
    ]">
              <el-input v-model="orderData.gjname"
                        placeholder="目的国家"></el-input>
            </el-form-item>

            <el-form-item label="省份"
                          prop='sjprovince'
                          :rules="[
      { required: true, message: '收件省份不能为空'}
    ]">
              <el-input v-model="orderData.sjprovince"
                        placeholder="国家省份"></el-input>
            </el-form-item>

            <el-form-item label="城市"
                          prop='sjcity'
                          :rules="[
      { required: true, message: '收件城市不能为空'}
    ]">
              <el-input v-model="orderData.sjcity"
                        placeholder="省份城市"></el-input>
            </el-form-item>

            <el-form-item label="区域"
                          prop='sjdistrict'>
              <el-input v-model="orderData.sjdistrict"
                        placeholder="城市区域"></el-input>
            </el-form-item>

            <el-form-item label="详细地址"
                          v-if="activePrintType==1"
                          prop='sjaddress'
                          style="width:65%; "
                          :rules="[
      { required: true, message: '详细地址不能为空'}
    ]">
              <el-input v-model="orderData.sjaddress"
                        placeholder="详细地址"></el-input>
            </el-form-item>

            <el-form-item label="邮政编码"
                          v-if="activePrintType==1"
                          prop='sjzipcode'
                          style="width:65%; "
                          :rules="[
      { required: true, message: '邮政编码不能为空'}
    ]">
              <el-input v-model="orderData.sjzipcode"
                        placeholder="收件人邮政编码"></el-input>
            </el-form-item>

            <el-form-item label="包裹数量"
                          v-if="activePrintType==1"
                          prop='bgnum'
                          :rules="[
      { required: true, message: '包裹数量不能为空'}
    ]">
              <el-input v-model.number="orderData.bgnum"
                        type="number"
                        placeholder="数量"></el-input>
            </el-form-item>

          </el-form>
          <el-table :data="tableData"
                    ref='selectWp'
                    @select='selectWp($event,false)'
                    @select-all='selectWp($event,true)'
                    v-if="activePrintType==2"
                    height="400"
                    style="width: 100%">
            <el-table-column prop="date"
                             type="selection"
                             width="50">
            </el-table-column>
            <el-table-column prop="name"
                             label="中文名"
                             width="70">
            </el-table-column>
            <el-table-column label="英文名"
                             width="90">
              <template #default="scope">
                <el-input v-model="scope.row.enname">
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="数量"
                             width="60">
              <template #default="scope">
                <el-input v-model="scope.row.num">
                </el-input>
              </template>
            </el-table-column>

            <el-table-column prop="unitprice"
                             label="单价">
              <template #default="scope">
                <el-input v-model="scope.row.unitprice">
                </el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="btn_view">
            <el-button v-print="printObj">
              打印
            </el-button>
          </div>
        </div>
      </div>
      <div class="main_right">
        <!-- 面单浏览 -->
        <div class="print_list"
             v-if="activePrintType==1"
             id="printJS-form">
          <div class="printView_item"
               v-for="item in orderData.bgnum"
               :key='item'>
            <div class="item_title">
              <div class="title_info">
                <el-image style="width: 10mm; height: 10mm"
                          src="https://www.tuanyun99.com/logo.png"></el-image>
                <div class="title_text">团运物流系统</div>
              </div>
              <div class="title_code">
                <svg :id='"top"+item'>{{barcode("top"+item,orderData.xz_weight_tj[item-1]?.zdnum,'','','',item)}}</svg>
              </div>
            </div>

            <div class="item_recipients">
              <div class="recipients_view">
                <div class="recipients_text">收件人：</div>
                <div class="recipients_content">{{orderData.sjname}}</div>
              </div>
              <div class="recipients_view">
                <div class="recipients_text">电话：</div>
                <div class="recipients_content">{{orderData.sjmobile}}</div>
              </div>
              <div class="recipients_view"
                   style="width:100%">
                <div class="recipients_text">地址：</div>
                <div class="recipients_content">{{`${orderData.gjname} ${orderData.sjprovince} ${orderData.sjcity} ${orderData.sjdistrict} ${orderData.sjaddress}`}}</div>
              </div>
              <div class="recipients_view">
                <div class="recipients_text">邮政编码：</div>
                <div class="recipients_content">{{orderData.sjzipcode}}</div>
              </div>
              <div class="recipients_view">
                <div class="recipients_text">主单号：</div>
                <div class="recipients_content">{{orderData.wlnum}}</div>
              </div>
            </div>

            <div class="item_stateAndNum">
              <div class="stateView">
                {{orderData.gjname}}
              </div>
              <div class="numView">
                {{item}}/{{orderData.bgnum}}
              </div>
            </div>

            <div class="item_code">
              <svg :id='"bottom"+item'>{{barcode("bottom"+item,orderData.xz_weight_tj[item-1]?.zdnum,2,'60px','18px',item)}}</svg>
            </div>

          </div>
        </div>
        <!-- 发票浏览 -->
        <div class="print_invoice"
             v-if="activePrintType==2"
             id="printJS-form">
          <div class="invoice_view">
            <div class="invoice_title">
              <span>INVOICE</span>
            </div>
            <div class="invoice_item">
              <div class="item_text">SOLOD TO:</div>
              <div class="item_content">{{`${orderData.gjname} ${orderData.sjprovince} ${orderData.sjcity} ${orderData.sjdistrict}`}}</div>
            </div>
            <div class="invoice_item">
              <div class="item_text">NO:</div>
              <div class="item_content">{{orderData.wlnum}}</div>
            </div>
            <el-table :data="activeTableData"
                      style="width: 100%">
              <el-table-column prop="enname">
                <template #header>
                  <span style="width:80px;color:#333; font-weight:700">DESCRIPTION</span>
                </template>
              </el-table-column>
              <el-table-column prop="num">
                <template #header>
                  <span style="width:80px;color:#333; font-weight:700">QUANTUTY</span>
                </template>
              </el-table-column>
              <el-table-column prop="unitprice">
                <template #header>
                  <span style="width:80px;color:#333; font-weight:700">Q/PRICE</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="invoice_bottom">
              <div class="bottom_item">
                <div class="item_text">DATA:</div>
                <div class="item_content">2021-6-11</div>
              </div>
              <div class="bottom_item">
                <div class="item_text">AMOUNT:</div>
                <div class="item_content">{{computeTotalPrices}} CNY</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postOrderDetails } from '@/request/api'
export default {
  name: 'printView',
  data () {
    return {
      // ----------选择的物品----------
      activeTableData: [],
      // ----------可选物品----------
      tableData: [],
      // ----------打印选项----------
      printObj: {
        id: "printJS-form",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      // ----------选择激活的打印类型----------
      activePrintType: '1',
      // ----------订单ID----------
      orderId: '',
      // ----------订单数据----------
      orderData: {},
      // ----------仓库ID----------
      wid: '',

    }
  },
  computed: {
    //   ----------计算总价----------
    computeTotalPrices () {
      let totalPrices = 0
      for (const key in this.activeTableData) {
        totalPrices += parseFloat(this.activeTableData[key].unitprice) * parseInt(this.activeTableData[key].num)
      }
      return totalPrices
    },
    activeTypeName () {
      switch (parseInt(this.activePrintType, 10)) {
        case 1:
          return '面单'
        case 2:
          return '发票'
        case 3:
          return '退货'
        default:
          return '面单'
      }
    }
  },
  methods: {
    //   ----------选择物品----------
    selectWp (e, falg) {
      if (e.length > 1 && falg) {
        this.$message.error('最多只能选择7个物品')
        this.$refs.selectWp.toggleAllSelection()
        return
      }
      if (e.length > 7) {
        this.$message.error('最多只能选择7个物品')
        return
      } else {
        this.activeTableData = e
      }
    },
    //   ----------物品数据提取----------
    wpinfo (res) {
      let arr = []
      for (const key in res) {
        for (const k in res[key].wpinfo) {
          arr.push(res[key].wpinfo[k])
        }

      }
      this.tableData = arr
    },
    //   ----------条形码----------
    barcode (id, value, w, h, s, item) {
      this.$nextTick(() => {
        let value_ = value
        if (item == 1) {
          value_ = this.orderData.wlnum
        } else if (!this.orderData.xz_weight_tj[item - 1]) {
          //   如果不存在，证明溢出包裹数量,就用主单号+索引
          value_ = this.orderData.wlnum + '-' + item
        }


        var JsBarcode = require('jsbarcode')
        JsBarcode('#' + id, value_, {
          format: 'CODE128',//选择要使用的条形码类型
          displayValue: true,//是否在条形码下方显示文字
          width: w || 1.2, //条宽度
          height: h || '40px', //高度
          font: 'Play',//设置文本的字体
          margin: 1,//设置条形码周围的空白边距
          fontSize: s || '14px',
        })
      })
    },
    //   ----------根据ID获取订单详情----------
    async getOrderDetails () {
      let res = await postOrderDetails({
        op: 'orderinfo',
        pd: 'getinfo',
        ckid: this.wid,
        orderid: this.$route.query.id
      })
      if (res.id) {
        this.orderData = res
        console.log(this.orderData.xz_weight_tj);
        res.bgnum = parseInt(res.bgnum, 10)
        this.wpinfo(res.xz_weight_tj)
      } else {
        this.$message.error(res.message)
      }
    }

  },
  async created () {
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    //  --------- 
    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
    }
    if (this.$route.query.manual) {
      this.$message.success('请在面单控制中心输入信息')
      this.orderData = {
        xz_weight_tj: []
      }
    } else {
      this.getOrderDetails()

    }
  }

}
</script>

<style lang='scss'>
.printView {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .printView_top {
    width: 95%;
    flex: 1;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .printView_main {
    flex: 1;
    width: 95%;
    display: flex;
    min-width: 0;
    min-height: 0;
    .main_left {
      max-width: 420px;
      height: 800px;
      flex: 1;
      background-color: #4c90fd;
      border-radius: 4px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      .left_title {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 2px;
        max-height: 25px;
        min-width: 0;
        min-height: 0;
      }
      .left_view {
        box-sizing: border-box;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        min-width: 0;
        min-height: 0;
        .el-table {
          td {
            padding: 5px 0;
          }
          .el-input__inner {
            font-weight: normal;
            padding: 5px;
            height: 30px;
          }
        }
        .btn_view {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          .el-button {
            border: 0;
          }
        }
        .el-form {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .el-form-item {
          width: 30%;
          margin-bottom: 5px;
        }
        .el-form-item__label {
          color: #fff;
          padding: 0;
        }
        .el-input__inner {
          padding: 10px;
          border: none;
          background-color: #f6f2f9;
          color: #5b60f1;
          font-weight: 700;
          text-align: center;
        }
      }
    }
    .main_right {
      height: 100%;
      flex: 1;
      min-width: 0;
      min-height: 0;
      padding: 10px;
      background-color: #d8d8d8;
      box-sizing: border-box;
      .print_list {
        justify-content: space-between;
        height: 100%;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        min-width: 0;
        min-height: 0;

        .stateView {
          border-right: 1px solid #333;
        }
        .item_recipients,
        .item_title,
        .item_stateAndNum {
          border-bottom: 1px solid #333;
        }
      }
      .print_invoice {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .printView_item {
        background-color: #fff;
        margin-bottom: 10px;
      }
    }
  }
}
.print_invoice {
  width: 100%;
  height: 100%;
  .invoice_view {
    width: 100mm;
    height: 100mm;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    color: #333;
    th {
      padding: 6px 0;
    }
    td {
      padding: 0px 0;
      font-size: 12px;
    }
    .invoice_item {
      display: flex;
      height: 9mm;
      font-size: 14px;
      align-items: center;
      border-bottom: 1px solid #333;
      .item_text {
        width: 24mm;
        // font-weight: 700;
      }
      .item_content {
        font-weight: 700;
      }
    }
    .invoice_title {
      font-size: 20px;
      font-weight: 700;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .invoice_bottom {
      width: 100%;
      display: flex;
      height: 10mm;
      align-items: center;
      border-top: 1px solid #333;

      .bottom_item {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        .item_text {
          margin-right: 10px;
        }
        .item_content {
          font-weight: 700;
        }
      }
    }
  }
}
.print_list {
  .stateView {
    border-right: 1px solid #000;
  }
  .item_recipients,
  .item_title,
  .item_stateAndNum {
    border-bottom: 1px solid #000;
  }
  .printView_item {
    page-break-after: always;
    width: 100mm;
    height: 100mm;
    padding: 1mm;
    box-sizing: border-box;
    .item_title {
      display: flex;
      align-items: center;
      height: 17mm;
      padding-bottom: 2px;
      .title_info {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .title_text {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1px;
        }
      }
      .title_code {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .item_recipients {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      .recipients_view {
        //   width: 50%;
        margin-bottom: 10px;
        font-size: 14px;
        .recipients_text {
          font-size: 12px;
        }
        .recipients_content {
          font-weight: 700;
          width: 100%;
        }
      }
    }
    .item_stateAndNum {
      width: 100%;
      display: flex;
      height: 15mm;
      .stateView,
      .numView {
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
      }
      .stateView {
      }
    }
    .item_code {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>